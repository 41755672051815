import { useStaticQuery, graphql, Link } from 'gatsby';
import classnames from 'classnames';
import React from 'react';

import { Tags } from './TagsComponent';

const PostCard = ({ article }) => (
  <div className="mb-4 group flex flex-col max-w-xs p-4 sm:m-0 rounded-md justify-around bg-white">
    <Link to={`/blog/${article.slug}`}>
      <img
        className="rounded-md"
        alt="this is used as cover of the post"
        src={article.social_image}
      />
    </Link>
    <div className="pb-2">
      <Tags tags={article.tags} />
    </div>
    <div className="h-12 flex items-center justify-between">
      <Link to={`/blog/${article.slug}`}>
        <h3 className="group-hover:underline">{article.title}</h3>
      </Link>
    </div>
  </div>
);

const QUERY_POSTS = graphql`
  query AllDevArticles {
    allDevArticles {
      edges {
        node {
          article {
            id
            title
            description
            tags
            slug
            positive_reactions_count
            published_at
            social_image
          }
        }
      }
    }
  }
`;

const CardList = ({ articles }) => (
  <div className="py-4 flex flex-col items-center sm:flex sm:flex-row sm:flex-wrap sm:justify-between">
    {articles.map(article => (
      <PostCard key={article.id} article={article} />
    ))}
  </div>
);

const Item = ({ article }) => (
  <li className="py-3 flex flex-col">
    <Link
      to={`/blog/${article.slug}`}
      className="text-primary-link font-bold hover:underline"
    >
      <h3>{`${article.title} →`}</h3>
    </Link>
    <div className="py-2">
      <p className="text-base sm:text-lg" style={{ maxWidth: '60ch' }}>
        {new Date(article.published_at).toLocaleDateString('en-GB')}
        {` `} | {` `}
        {article.description}
      </p>
    </div>
  </li>
);

const List2 = ({ articles }) => (
  <ul className="py-4 text-lg">
    {articles.map(article => {
      return <Item key={article.id} article={article} />;
    })}
  </ul>
);

const List = ({ articles }) => (
  <ul className="py-4 text-lg list-disc pl-4">
    {articles.map(article => {
      return (
        <li>
          <Link
            to={`/blog/${article.slug}`}
            className="text-primary-link hover:underline"
          >
            {new Date(article.published_at).toLocaleDateString()}{' '}
            {article.title} →
          </Link>
        </li>
      );
    })}
  </ul>
);

const Posts = ({ home = false }) => {
  const data = useStaticQuery(QUERY_POSTS);
  const articles = data.allDevArticles.edges.map(e => e.node.article);
  const sortedArticles = articles.sort((articleA, articleB) => {
    const { published_at: published_atA } = articleA;
    const { published_at: published_atB } = articleB;
    const dateATime = new Date(published_atA).getTime();
    const dateBTime = new Date(published_atB).getTime();

    if (dateATime < dateBTime) return 1;
    if (dateATime > dateBTime) return -1;
    return 0;
  });
  const className = classnames('flex flex-col', { 'pt-10': !home });

  return (
    <div className={className}>
      <h2 className="text-2xl">{home ? 'posts' : 'Recent Posts'}</h2>
      {home ? (
        <List articles={sortedArticles} />
      ) : (
        <CardList articles={sortedArticles} />
      )}
      {home ? (
        <Link to="/blog/" className="hover:underline text-sm">
          View all →
        </Link>
      ) : (
        <p className="text-xlg">
          {' '}
          Posts older than 2019 are still available on my{' '}
          <a
            href="https://medium.com/@peaonunes"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline text-primary-link"
          >
            Medium profile
          </a>
          .
        </p>
      )}
    </div>
  );
};

export { Posts };
