import React from 'react';

import { HomeLayout } from '../components/Layout';
import { Speaking } from '../components/Speaking';
import { Drops } from '../components/Drops';
import { Posts } from '../components/Posts';
import { Tags } from '../components/Tags';
import Scene from './../components/Scene';

const IndexPage = () => (
  <HomeLayout>
    <div className="max-w-4xl m-auto flex flex-col sm:flex-row-reverse py-10 px-2">
      <aside className="hidden sm:block sm:w-1/3 text-justify">
        <section>
          <h2 className="text-2xl">
            hello{' '}
            <span role="img" aria-label="smile emoji">
              😄
            </span>
          </h2>
          <p style={{ zIndex: 1 }}>
            I'm Rafael Nunes, full time software engineer and learner. Working
            on empowering people to realise the full value of their skills at{' '}
            <a
              className="text-primary-link hover:underline"
              href="https://airtasker.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Airtasker
            </a>
            .
          </p>
          <p style={{ zIndex: 1 }}>Casual blog post writer & open sourcerer.</p>
        </section>
        {/* <section>
          <div style={{ zIndex: 0 }} className="absolute w-full">
            <Scene />
          </div>
        </section> */}
        <section className="py-20 hidden sm:block">
          <Tags home />
        </section>
      </aside>
      <main className="main sm:w-2/3">
        <section>
          <Posts home />
        </section>
        <section className="pt-10">
          <Drops home />
        </section>
        <section className="pt-10">
          <Speaking />
        </section>
        <section className="pt-10 sm:hidden">
          <Tags home />
        </section>
      </main>
    </div>
  </HomeLayout>
);

export default IndexPage;
