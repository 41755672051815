import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import { BaseTags } from './TagsComponent';

const QUERY_DROPS = graphql`
  query {
    allMarkdownRemark {
      nodes {
        html
        frontmatter {
          title
          slug
          date
          tags
        }
      }
    }
  }
`;

const DropCard = ({ article }) => {
  const {
    frontmatter: { slug, tags, title },
  } = article;
  const className = 'py-2 group flex flex-col max-w-lg justify-around';

  return (
    <div className={className}>
      <div className="h-10 flex items-center justify-between">
        <Link to={`/drops/${slug}`}>
          <h3 className="group-hover:underline group-hover:text-primary-link">
            {title}
          </h3>
        </Link>
      </div>
      <div className="h-10 flex items-center justify-between">
        <BaseTags
          className="flex text-sm"
          wrapperClass="pr-2"
          tagClass="p-2"
          tags={tags}
        />
      </div>
    </div>
  );
};

const CardList = ({ drops }) => (
  <div className="py-4 flex flex-col sm:flex sm:flex-col sm:flex-wrap">
    {drops.map(drop => (
      <DropCard key={drop.frontmatter.slug} article={drop} />
    ))}
  </div>
);

const Item = ({ drop }) => (
  <li className="flex flex-col py-2">
    <Link
      to={`/drops/${drop.frontmatter.slug}`}
      className="hover:underline hover:text-primary-link"
    >
      {drop.frontmatter.title} →
    </Link>
    <span className="text-xs">
      {new Date(drop.frontmatter.date).toLocaleDateString('en-GB')}
    </span>
  </li>
);

const List2 = ({ drops }) => (
  <ul className="py-4 text-lg">
    {drops.map(drop => (
      <Item key={drop.frontmatter.slug} drop={drop} />
    ))}
  </ul>
);

const List = ({ drops }) => (
  <ul className="py-4 text-lg list-disc pl-4">
    {drops.map(drop => {
      return (
        <li key={drop.slug}>
          <Link
            to={`/drops/${drop.frontmatter.slug}`}
            className="text-primary-link hover:underline"
          >
            {new Date(drop.frontmatter.date).toLocaleDateString()}{' '}
            {drop.frontmatter.title} →
          </Link>
        </li>
      );
    })}
  </ul>
);

const Drops = ({ home = false }) => {
  const drops = useStaticQuery(QUERY_DROPS);
  const nodes = drops.allMarkdownRemark.nodes;
  const sortedNodes = nodes.sort((nodeA, nodeB) => {
    const {
      frontmatter: { date: dateA },
    } = nodeA;
    const {
      frontmatter: { date: dateB },
    } = nodeB;
    const dateATime = new Date(dateA).getTime();
    const dateBTime = new Date(dateB).getTime();

    if (dateATime < dateBTime) return 1;
    if (dateATime > dateBTime) return -1;
    return 0;
  });

  const className = classnames('flex flex-col', { 'pt-10': !home });

  return (
    <div className={className}>
      <div>
        <h2 className="text-2xl">{home ? 'drops' : 'Recent Drops'}</h2>
      </div>
      {home ? <List drops={sortedNodes} /> : <CardList drops={sortedNodes} />}
      {home ? (
        <Link to="/drops/" className="hover:underline text-sm">
          View all →
        </Link>
      ) : (
        <p className="text-lg">
          Drops of content, code snippets, random thoughts, TILs, and other
          tips.
        </p>
      )}
    </div>
  );
};

export { Drops };
