import React from 'react';

const SPEAKING = [
  {
    title: 'Why is using GraphQL being so good for our team?',
    date: '2019-02-13',
    location: 'Recife, Brazil',
    event: 'GraphQL Recife',
    eventLink: 'https://github.com/graphqlrecife/meetup',
    slides: 'https://bit.ly/graphqlrecife1-inloco',
  },
  {
    title: 'Feature Flag approach with GraphQL, Apollo and React',
    date: '2020-06-18',
    location: 'Online',
    event: 'GraphQL Sydney',
    eventLink: 'https://github.com/graphqlsydney/graphqlsydney',
    slides:
      'https://slides.com/peaonunes/feature-flag-approach-with-graphql-and-react',
    video: 'https://www.youtube.com/watch?v=A4OP1jeWu_k',
  },
  {
    title: 'Circuit Breaker in Ruby with Circuitbox',
    date: '2020-08-11',
    location: 'Online',
    event: 'ROROSyd',
    eventLink: 'https://github.com/rails-oceania/roro',
    slides:
      'https://slides.com/peaonunes/circuit-breaker-in-ruby-with-circuitbox',
    video: 'https://www.youtube.com/watch?v=33l9ROqmapk',
  },
  {
    title: 'Circuit Breaker em Ruby com Circuitbox',
    date: '2021-01-27',
    location: 'Online',
    event: 'ada.rb -  Arquitetura e Design de Aplicações Ruby',
    eventLink: 'https://www.flowcode.com/page/adarb/',
    slides:
      'https://slides.com/peaonunes/circuit-breaker-in-ruby-with-circuitbox-d03917',
    video: 'https://youtu.be/fNZviKKU_lI?t=2197',
  },
  {
    title: 'The N+1 Problem',
    date: '2021-02-09',
    location: 'Online',
    event: 'ROROSyd',
    eventLink: 'https://github.com/rails-oceania/roro',
    slides: 'https://slides.com/peaonunes/n1andeagerloadingandbeyond',
    video: 'https://www.youtube.com/watch?v=5ou4kZtgnoY',
  },
];

const byDate = (speakingA, speakingB) => {
  const dateA = new Date(speakingA.date);
  const dateB = new Date(speakingB.date);

  if (dateA.getTime() > dateB.getTime()) return -1;
  if (dateB.getTime() > dateA.getTime()) return 1;
  return 0;
};

const Speaking = () => (
  <div>
    <h2 className="text-2xl">speaking</h2>
    <div className="flex flex-col text-lg">
      {SPEAKING.sort(byDate).map(speaking => {
        return (
          <div className="py-2" key={speaking.title}>
            <h3>
              {speaking.title}
              {!!speaking.slides && (
                <a
                  rel="noopener noreferrer"
                  href={speaking.slides}
                  target="_blank"
                  className="hover:underline px-2"
                >
                  <span role="img" aria-label="slides link">
                    🗒
                  </span>
                </a>
              )}
              {!!speaking.video && (
                <a
                  rel="noopener noreferrer"
                  href={speaking.video}
                  target="_blank"
                  className="hover:underline"
                >
                  <span role="img" aria-label="recording link">
                    🎞
                  </span>
                </a>
              )}
            </h3>
            <div className="text-xs flex justify-between">
              <p>
                <a
                  rel="noopener noreferrer"
                  href={speaking.eventLink}
                  target="_blank"
                  className="hover:underline text-primary-link"
                >
                  {speaking.event}
                </a>{' '}
                | {speaking.location} |{' '}
                {new Date(speaking.date).toLocaleDateString('en-GB')}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export { Speaking };
